import React from "react";

import "./PopUp.scss";

import { useTranslation } from "react-i18next";
import { CSSTransition } from "react-transition-group";
import { StaticImage } from "gatsby-plugin-image";
import { Trans } from "react-i18next";

import { addCookie, getCookie } from "./Cookies";

import IconClose from "../../svg/iconClose.svg";

export default function PopUp() {
	const { t } = useTranslation("popUp");
	const [popUpClosed, setPopUpClosed] = React.useState(true);
	const eventDeadline = React.useMemo(
		() => new Date("14 Feb 2025 23:30:00 GMT+0100"),
		[]
	);

	const months = [
		t("Jan"),
		t("Feb"),
		t("Mar"),
		t("Apr"),
		t("May"),
		t("Jun"),
		t("Jul"),
		t("Aug"),
		t("Sep"),
		t("Oct"),
		t("Nov"),
		t("Dec"),
	];

	React.useEffect(() => {
		const currentTime = new Date();

		if (eventDeadline > currentTime) {
			setTimeout(() => setPopUpClosed(getCookie("popUpClosed")), 5000);
		}
		return;
	}, [eventDeadline]);

	const handlePopUpClose = () => {
		addCookie("popUpClosed");
		setPopUpClosed(true);
	};

	return (
		<CSSTransition
			in={!popUpClosed}
			timeout={750}
			classNames="fade"
			unmountOnExit
			mountOnEnter
			appear
		>
			<div className="popUpContainer">
				<button
					className="popUpOverlay"
					onClick={() => handlePopUpClose()}
					aria-label="Close pop up"
				/>
				<div className="popUpContent">
					<button
						onClick={() => handlePopUpClose()}
						className="buttonClose"
						aria-label={t("Close pop-up")}
					>
						<IconClose />
					</button>

					<p>
						<Trans ns="popUp">upcoming event</Trans>
					</p>

					{/* Si hay foto, ocultar título y texto */}
					{/*<p className="date">
						<i>
							<Trans ns="popUp">dinner on</Trans> {eventDeadline.getDate()}{" "}
							<Trans ns="popUp">of</Trans> {months[eventDeadline.getMonth()]}
						</i>
					</p>*/}
					<p className="date">
						<i>
							<Trans ns="popUp">special lunch on 14th of February</Trans>
						</i>
					</p>
					<h2>
						{/* {eventDeadline.getDate()}/{eventDeadline.getMonth() + 1}
						&emsp; */}
						<Trans ns="popUp">Valentines event.</Trans>
					</h2> 
				
					{/* LINK A MENU: Si hay nuevo pdf, subirlo a /static/pdf. Si no lo hay, comentar */}
					<a
						href="/pdf/popupMenu.pdf"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Trans ns="popUp">see special menu</Trans>
					</a>
							
					{/* IMAGEN DEL EVENTO */}
					<StaticImage
						src="../../images/popUp.jpg"
						alt={t("An image of Bossa's upcoming event")}
					/>

					<br></br>
					<br></br>
										
						{/* Si hay foto, ocultar título y texto */}
					{/* <p className="date">
						<i>
							<Trans ns="popUp">special night on 14th of February</Trans>
						</i>
					</p>
					
					

					<h2>
						<Trans ns="popUp">14/02 Valentines menu.</Trans>
					</h2>

					<p>
						<Trans ns="popUp">
							Join us in this very special event
						</Trans>
						.
					</p> */}

					{/* LINK A MENU: Si hay nuevo pdf, subirlo a /static/pdf. Si no lo hay, comentar */}
					{/* <a
						href="/pdf/popupMenu2.pdf"
						target="_blank"
						rel="noopener noreferrer"
					>
						<Trans ns="popUp">see our special menu</Trans>
					</a> */}
				</div>
			</div>
		</CSSTransition>
	);
}
